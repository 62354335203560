'use client';

import {useEffect} from "react";

import {handleCopy} from "@/lib/utils";

export function DisableCopyAction() {
  useEffect(() => {
    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
    };
    const handleCopyAction = (e: ClipboardEvent) => {
      e.preventDefault();
      if(typeof window !== 'undefined') handleCopy(window.location.href);
    };
    document.addEventListener("copy", handleCopyAction);
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("copy", handleCopyAction);
    };
  }, []);
  return null;
}